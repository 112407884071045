<template>
  <div>
    <div class="site-section bg-light">
      <h1 class="mb-2" style="text-align: center;">{{$t("pages.about.company.title")}}</h1>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12" data-aos="fade-up" data-aos-delay="100">
            <br />
            
            <p v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0"
              style="text-align:center; font-size:18px; text-transform: uppercase">
              {{$t("pages.about.company.description")}}
            </p>
            <p class="jump-line" v-else
              style="text-align:center; font-size:18px; text-transform: uppercase">
              <About 
                :aboutx="activeLocale == 'es' || !info.about_us_eng
                ? info.about_us	 : info.about_us_eng"
                />
            </p>
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="post-content  col-md-10" align="center">
              <p class="quote">

                <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
                  > {{$t("pages.about.slogan")}}</span
                >
                <span v-else class="jump-line">
                  <Slogan 
                    :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                    ? info.our_slogan	 : info.our_slogan_eng"
                    />
                </span>
              </p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.mision.title")}}</h2>
                <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" 
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                  {{$t("pages.about.mision.description")}}
                </p>
                <p v-else
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
                  <Mision 
                    :misionx="activeLocale == 'es' || !info.our_mission_eng
                    ? info.our_mission	 : info.our_mission_eng"
                    />
                </p>
            </div>

            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.vision.title")}}</h2>
                <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;" >
                    {{$t("pages.about.vision.description")}}
                </p>
                <p v-else
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
                  <Vision 
                    :visionx="activeLocale == 'es' || !info.our_vision_eng
                    ? info.our_vision	 : info.our_vision_eng"
                    />
                </p>
            </div>
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.values.title")}}</h2>
                <p v-if="info.our_values == 0 & info.our_values_eng == 0"
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;" >
                <ul >
                  <li>
                    {{$t("pages.about.values.example_one.description")}}

                  </li>
                  <li>{{$t("pages.about.values.example_two.description")}}</li>
                </ul>
                </p>
                <p v-else
                  style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
                  <Values 
                    :valuesx="activeLocale == 'es' || !info.our_values_eng
                    ? info.our_values	 : info.our_values_eng"
                    />
                </p>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { mapGetters } from "vuex";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
    components: {
      About,
      Slogan,
      Mision,
      Vision,
      Values,

  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
     window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>