<template>
    <section>
        <img src="/images/rec-room.jpg" class="card-img image-slide-back"/>
        <div class="superposicion"></div>
        <div v-if="info.logo" class="watermark img-logo" :style="'background-image:url('+info.logo+'); '"> </div>

                
    </section>
</template>

<script>
import { VueAgile } from 'vue-agile';
import {mapGetters} from "vuex";
import SectionLoader from "@/components/layout/SectionLoader.vue";
import services from '@/store/_services';
export default {
    components: {
        VueAgile,
        SectionLoader
    },
    props:{
        items:{
            type:Array,
            required:true
        }
    },
    computed: {
        ...mapGetters({
            info:'_getInfoCompany',
        })
    },
    created(){
        this.origin=this.$route.name;
    }
}
</script> 

<style >
    .img-logo{
        height: 40%;
        top: 0%;
        z-index: 3;
    }
    .image-slide-back{
        height:  50vh;
        object-fit: cover;
        /* opacity: 0.5; */
    }
    .superposicion {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50vh;
        background-color: rgba(255, 246, 246, 0.432); 
    }
    
    @media only screen and (max-width:991px){
        .image-slide-back{
        height:45vh;
        object-fit: cover;
        }
        .superposicion {
        height: 45vh;
        }   
        .img-logo{
            top: 3%;
        height: 30%;
    }
    }
</style>
