<template>
  <div class="col-md-6 col-lg-4 mb-4">
    <div class="property-entry h-100">

      <div class="alert alert-success" v-if="showAlert" style="position: absolute; z-index: 9; top: 12%; left: 50%;">
          URL copiada con éxito
        </div>
      
      <a @click="copyURLAndScrollToSection(item.zone, item.colony)"
        class="btn btn-primary btn-floating-left rounded-circle btn-share" data-toggle="tooltip" data-placement="top" title="Copiar URL">
        <i class="fas fa-share-alt"></i>
      </a>

      <div class="property-thumbnail">
        <div class="offer-type-wrap">
          <span class="offer-type bg-danger">#{{ item.folio }}</span>
        </div>

        <div v-if="info.logo" class="watermark" :style="'background-image:url(' + info.logo + '); '"> </div>
        <img id="propImg" :src="item.image.smallphoto" :alt="item.image.alt" :title="item.image.title"
          class="img-fluid" />
      </div>

      <div class="p-4 property-body">
        <strong class="property-price mb-0 d-block text-success">
          {{ item.developmentNameEs }}
        </strong>

        <h2 v-if="item.publicity_files" class="property-title">
          <a :href="item.publicity_files" target="_blank" rel="noopener noreferrer">
            Folleto
          </a>
        </h2>

        <h2 class="property-title">
          <a @click="openModal" data-toggle="modal" :data-target="'#exampleModal'">
            Galería
          </a>
        </h2>

        <h2 v-if="item.video" class="property-title">
          <a :href="item.video" target="_blank" rel="noopener noreferrer">
            Video
          </a>
        </h2>
      </div>

    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import { mapGetters } from "vuex";
import services from "@/store/_services";
import DetailCarousel from "../carousel/DetailCarousel.vue";
import GalleryCarousel from "../carousel/GalleryCarousel.vue";
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default {
  components: {
    DetailCarousel,
    GalleryCarousel,
    VueAgile
  },

  props: [
    'name',
    'index',
    'item'],
  data() {
    return {
      folio: null,
      agentId: 0,
      url: "",
      amenities: [],
      showModal: false,
      isModalOpen: false,
      showAlert: false
    };
  },
  created() {
    this.scrollToSection();
  },
  computed: {
    ...mapGetters({
      info: '_getInfoCompany',
      agent: '_getDataAgent',
    }),
  },
  methods: {
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    copyURLAndScrollToSection(zone, colony) {
      // Función para formatear una cadena eliminando espacios y convirtiendo a minúsculas
      function formatString(str) {
        return this.removeAccents(str.toLowerCase()).replace(/\s+/g, '-');
      }

      // Formatear las zonas y colonias
      let formattedZone = formatString.call(this, zone);
      let formattedColony = formatString.call(this, colony);

      // Obtener la URL base del sitio web
      let baseURL = window.location.origin + window.location.pathname;

      // Generar la URL con el fragmento de anclaje
      let url = `${baseURL}#${formattedZone}-${formattedColony}`;

      // Copiar la URL al portapapeles
      navigator.clipboard.writeText(url)
        .then(() => {
          console.log('URL copiada con éxito');
        })
        .catch(err => {
          console.error('Error al copiar la URL: ', err);
        });
        this.showAlert = true; 
        setTimeout(() => {
          this.showAlert = false;
        }, 2500);
    },

    scrollToSection() {

      let hash = window.location.hash;

      // dirige a zona hotelera
      if (hash === "#division-desarrollos-zona-hotelera") {
        setTimeout(() => {
          let section = document.getElementById("division-zona-hotelera");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a puerto cancun
      else if (hash === "#division-desarrollos-puerto-cancun") {
        setTimeout(() => {
          let section = document.getElementById("division-puerto-cancun");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a Moré
      else if (hash === "#la-veleta-tulum-la-veleta") {
        setTimeout(() => {
          let section = document.getElementById("master-more-tulum");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a Kalista
      else if (hash === "#master-broker-isla-de-holbox") {
        setTimeout(() => {
          let section = document.getElementById("master-kalista-holbox");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      //seccion con url fijas que no debe ser así 
      else if (hash === "#division-desarrollos-supermanzana-329---av.-huayacan-") {
        setTimeout(() => {
          let section = document.getElementById("division-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#division-desarrollos-alamos-ll") {
        setTimeout(() => {
          let section = document.getElementById("division-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#division-desarrollos-supermanzana-323") {
        setTimeout(() => {
          let section = document.getElementById("division-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#division-desarrollos-jardines-del-sur") {
        setTimeout(() => {
          let section = document.getElementById("division-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }

      //termina seccion especial
      // si no hay nada después de la ulr, se mantiene en el incio
      else if (hash === "") {
        return;
      }
      else if (hash === "#division-desarrollo-cancun-centro"){
        setTimeout(() => {
          let section = document.getElementById("division-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
    },
    openModal() {
      this.isModalOpen = true;
      this.$emit('folio-selected', this.item.folio);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    goToDevelopment() {
      let nameDevFilt = services.normalize(this.item.nameDevFilt);
      let folio = this.item.folio;
      let namePage = "Property";
      let params;

      if (this.nameRoute == 'Agent') {
        namePage = "DevelopmentAgent";
        params = {
          folio,
          agentId: this.agent.folio
        }
      } else {
        params = {
          folio,
          nameDevFilt
        }
      }

      this.$router.push({
        name: namePage,
        params: params
      });

    },
  },
};
</script>

<style scoped>
.not-price {
  color: red !important;
  text-decoration: line-through;
}

img {
  width: 100%;
}

#propImg {
  height: 250px;
  width: 350px;
  cursor: pointer;
  object-fit: cover;
}

@media (max-width: 767px) {
  #contain_tarjet_property {
    width: 65%;
  }

  #propImg {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 520px) {
  #contain_tarjet_property {
    width: 100%;
  }
}

#leyendaPrecios {
  color: #000;
  font-size: 9px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bg-danger {
  background-color: #C51333bf !important;
}

.watermark {
  height: 250px;
  width: 100%;
  background-size: 30%;
  z-index: 1;
}

.watermark {
  opacity: .3;
}
</style>
