<template>
<Preloader v-if="!prop"/>
<div v-else>
    <!-- Carousel -->
    <DetailCarousel :items="prop.images"/>

</div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue"
import Preloader from "@/components/layout/Preloader.vue";
export default {
  components: {
    Preloader,
    DetailCarousel,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
      error: "_getError",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;

  }
};
</script>


<style scoped>
</style>
