<template lang="html">
	<!--<ip-search v-if="searchCompleted" :properties="propertiesFound"></ip-search>-->
	<div>
		<!-- Slider
		===========s======================================= -->
		<HomeCarousel :items="items"/>
		<!-- <DetailCarousel :items="prop.images"/> -->
    
    <div class="wrapper">
      <a href="mailto:coordinadora@costarealty.com.mx" class="creditohipo" target="_blank">
        <i class="far fa-envelope"></i>
      </a>
        <div class="section-shadow"></div>
    </div>

    <a href="https://api.whatsapp.com/send?phone=9983210234" class="whatsappb" target="_blank"> <i class="icon-whatsapp"></i></a>

        
    
	  <!-- developments DIVISIÓN DESARROLLOS
		================================================== -->
    <div v-if="developments && developments.some(item => item.zone === 'División Desarrollos')"> 
      <div class="form-search sticky">
        <h1 class="text-center "> DIVISIÓN DESARROLLOS </h1>
      </div> 
      <div>    
        <div class="site-section site-section-sm bg-light">      
          <div class="container">
              <!-- ZONA HOTELERA -->
            <div id="division-zona-hotelera" v-if="developments && developments.some(item => item.colony === 'Zona Hotelera')">
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> ZONA HOTELERA</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.colony === 'Zona Hotelera' && item.zone === 'División Desarrollos'"
                  @folio-selected="openGalleryCarousel" 
                  />
              </div>
            </div>
            <!--PUERTO CANCUN -->
            <div id="division-puerto-cancun" v-if="developments && developments.some(item => item.colony === 'Puerto Cancún')">
                  <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> PUERTO CANCÚN </h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.colony === 'Puerto Cancún' && item.zone === 'División Desarrollos'"
                  @folio-selected="openGalleryCarousel" 
                  />
              </div>
            </div>
                <!-- CENTRO -->
            <div id="division-cancun-centro" v-if="developments && developments.some(item => item.colony !== 'Puerto Cancún' && item.colony !== 'Zona Hotelera')">
                  <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> CANCÚN CENTRO </h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.colony !== 'Puerto Cancún' && item.colony !== 'Zona Hotelera' && item.zone === 'División Desarrollos'"
                  @folio-selected="openGalleryCarousel" 
                  />
              </div>
            </div>
          </div>	
        </div>      
      </div> 
    </div>
    <!--  Properties CORRETAJE
		================================================== -->  
    <div v-if="properties && properties.some(property => property.zone === 'Corretaje')">
      <div class="form-search mt-3 sticky">
        <h1 class="text-center"> CORRETAJE </h1>      
      </div> 
    
      <div>
        <div class="site-section site-section-sm bg-light">      
          <div class="container">
            <!-- ZONA HOTELERA -->
            <div id="corretaje-zona-hotelera" v-if="properties && properties.some(property => property.colony === 'Zona Hotelera')">
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest"> ZONA HOTELERA</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" />
                <PropertyCard
                  v-for="(property, i) in properties"
                  :key="property.folio"
                  :name="property.folio"
                  :index="i"
                  :item="property"
                  v-if="property.colony === 'Zona Hotelera' && property.zone === 'Corretaje'"
                  @folio-selected="openGalleryCarousel" 
                  /> 
              </div>
            </div>
            <!-- PUERTO CANCUN-->
            <div id="corretaje-puerto-cancun" v-if="properties && properties.some(property => property.colony === 'Puerto Cancún')">
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest"> PUERTO CANCÚN</h2>          
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                      <PropertyCard
                        v-for="(property, i) in properties"
                        :key="property.folio"
                        :name="property.folio"
                        :index="i"
                        :item="property"
                        v-if="property.colony === 'Puerto Cancún' && property.zone === 'Corretaje'"
                        @folio-selected="openGalleryCarousel" 
                        /> 
              </div>
            </div>
            <!-- CENTRO -->
            <div id="corretaje-cancun-centro" v-if="properties && properties.some(property => property.colony !== 'Puerto Cancún' && property.colony !== 'Zona Hotelera')">
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest"> CANCÚN CENTRO</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                  <PropertyCard
                    v-for="(property, i) in properties"
                    :key="property.folio"
                    :name="property.folio"
                    :index="i"
                    :item="property"
                    v-if="property.colony !== 'Puerto Cancún' && property.colony !== 'Zona Hotelera' && property.zone === 'Corretaje'"
                    @folio-selected="openGalleryCarousel" 
                    /> 
              </div>  
            </div>
          </div>	
        </div>      
      </div>
    </div>
    <!-- properties COMERCIAL INDUSTRIAL
		================================================== -->  
    <div v-if="properties && properties.some(property => property.zone === 'Comercial Industrial')">
      <div class="form-search mt-3 sticky">
        <h1 class="text-center"> COMERCIAL INDUSTRIAL </h1>      
      </div> 
    
      <div>
        <div class="site-section site-section-sm bg-light">      
          <div class="container">
            <!-- ZONA HOTELERA -->
            <div id="comercial-zona-hotelera" v-if="properties && properties.some(property => property.colony === 'Zona Hotelera')">
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> ZONA HOTELERA</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" />
                      <PropertyCard
                        v-for="(property, i) in properties"
                        :key="property.folio"
                        :name="property.folio"
                        :index="i"
                        :item="property"
                        v-if="property.colony === 'Zona Hotelera' && property.zone === 'Comercial Industrial'"
                        @folio-selected="openGalleryCarousel" 
                        />
              </div>
            </div>
            <!-- PUERTO CANCUN-->
            <div id="comercial-puerto-cancun" v-if="properties && properties.some(property => property.colony === 'Puerto Cancún')">
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> PUERTO CANCÚN</h2>          
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                      <PropertyCard
                        v-for="(property, i) in properties"
                        :key="property.folio"
                        :name="property.folio"
                        :index="i"
                        :item="property"
                        v-if="property.colony === 'Puerto Cancún' && property.zone === 'Comercial Industrial' "
                        @folio-selected="openGalleryCarousel" 
                        /> 
              </div>
            </div>
            <!-- CENTRO -->
            <div id="comercial-cancun-centro" v-if="properties && properties.some(property => property.colony !== 'Puerto Cancún' && property.colony !== 'Zona Hotelera')">
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"> CANCÚN CENTRO</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                  <PropertyCard
                    v-for="(property, i) in properties"
                    :key="property.folio"
                    :name="property.folio"
                    :index="i"
                    :item="property"
                    v-if="property.colony !== 'Puerto Cancún' && property.colony !== 'Zona Hotelera' && property.zone === 'Comercial Industrial' "
                    @folio-selected="openGalleryCarousel" 
                    /> 
              </div>  
            </div>
          </div>	
        </div>      
      </div>
    </div>
        <!-- developments MASTER BROKER
		================================================== -->  
    <div v-if="developments && developments.some(item => item.zone === 'Máster Bróker')">
      <div class="form-search mt-3 sticky">
        <h1 class="text-center"> MASTER BROKER </h1>      
      </div> 
      <div>    
        <div class="site-section site-section-sm bg-light">      
          <div class="container">
            <!-- TULUM -->
            <div id="master-more-tulum" v-if="developments && developments.some(item => item.city === 'Tulum')">
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest"> MORÉ - TULUM</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.city === 'Tulum'"
                  @folio-selected="openGalleryCarousel" 
                />
              </div> 
            </div>
            <!-- HOLBOX item.city === 'Tulum' && item.zone='Master Broker' -->
            <div id="master-kalista-holbox" v-if="developments && developments.some(item => item.city === 'Holbox')">
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest">KALISTA - HOLBOX</h2>        
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.city === 'Holbox'  && item.zone === 'Máster Bróker' "
                  @folio-selected="openGalleryCarousel" 
                />
              </div>
            </div>
            <!-- centro -->
            <!-- <div>
                <h2 class="text-center sticky-txt-simple px-3 mb-3 property-offer-type rounded text-dest"> CANCÚN CENTRO</h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!developments"/>
                <DevelopmentCard
                  v-for="(item, i) in developments"
                  :key="item.folio"
                  :name="item.folio"
                  :index="i"
                  :item="item"
                  v-if="item.colony === 'Zona Hotelera'"
                />
              </div>	
            </div> -->
          </div>	
        </div>      
      </div> 
    </div>
    <!-- properties PROPIEDADES SELECTAS
		================================================== -->  
    <div v-if="properties && properties.some(property => property.zone === 'Propiedades Selectas')">
      <div class="form-search mt-3 sticky">
        <h1 class="text-center"> PROPIEDADES SELECTAS </h1>      
      </div> 
    
      <div>
        <div class="site-section site-section-sm bg-light">      
          <div class="container">
            <!-- ZONA HOTELERA -->
            <div >
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"></h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" />
                      <PropertyCard
                        v-for="(property, i) in properties"
                        :key="property.folio"
                        :name="property.folio"
                        :index="i"
                        :item="property"
                        v-if="property.zone === 'Propiedades Selectas'"
                        @folio-selected="openGalleryCarousel" 
                        />
              </div>
            </div>
            <!-- PUERTO CANCUN-->
            <!-- <div>
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"></h2>          
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                      <PropertyCard
                        v-for="(property, i) in properties"
                        :key="property.folio"
                        :name="property.folio"
                        :index="i"
                        :item="property"
                        v-if="property.zone === 'Propiedades Selectas' "
                        @folio-selected="openGalleryCarousel" 
                        /> 
              </div>
            </div> -->
            <!-- CENTRO -->
            <!-- <div>
                <h2 class="text-center sticky-txt px-3 mb-3 property-offer-type rounded text-dest"></h2>      
              <div class="row mb-5 justify-content-center">
                <SectionLoader v-if="!properties" /> 
                  <PropertyCard
                    v-for="(property, i) in properties"
                    :key="property.folio"
                    :name="property.folio"
                    :index="i"
                    :item="property"
                    v-if="property.zone === 'Propiedades Selectas' "
                    @folio-selected="openGalleryCarousel" 
                    /> 
              </div>  
            </div>   -->
          </div>	
        </div>      
      </div>
    </div>
    <!-- end.
		================================================== --> 
    <GalleryCarousel v-if="isGalleryOpen" :folio="selectedFolio" @close="closeGalleryCarousel" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import SectionLoader from "@/components/layout/SectionLoader.vue";
import DetailCarousel from "../../components/carousel/DetailCarousel.vue";
import GalleryCarousel from "../../components/carousel/GalleryCarousel.vue";

export default {
  components: {
    DevelopmentCard,
    HomeCarousel,
    SearchForm,
    PropertyCard,
    SectionLoader, 
    DetailCarousel,
    GalleryCarousel
  },
  data() {
    return {
      isGalleryOpen: false,
      selectedFolio: null
    };
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      developments: "_getDevelopments",
      inputs: "_getInputs",
      error:'_getError', 
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
    this.$store.dispatch("getDevelopments");
    this.$store.dispatch("getInputs"); 
  },
  methods: {
    openGalleryCarousel(folio) {
      this.isGalleryOpen = true;
      this.selectedFolio = folio;
    },
    closeGalleryCarousel() {
      this.isGalleryOpen = false;
      this.selectedFolio = null;
    }
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}
.sticky{
  position: sticky;
  top: 0;
  z-index: 999;
}
.sticky-txt, .sticky-txt-simple{
  position: sticky;
  top: 88px;
  z-index: 998;
  background-color: #f9f9f9bd;
}

@media (max-width: 543px) {
  .sticky-txt{
    top: 136px;
  }
}


.bg {
  height: 14%;
  animation:slide 6s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(112, 114, 110) 50%, rgb(61, 51, 51) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0px;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:8s;
}

.bg3 {
  animation-duration:10s;
}



@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

</style>