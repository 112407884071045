<template>
  <div class="col-md-6 col-lg-4 mb-4">
    <div class="property-entry h-100">

      <div class="alert alert-success" v-if="showAlert" style="position: absolute; z-index: 9; top: 12%; left: 50%;">
          URL copiada con éxito
        </div>

      <a @click="copyURLAndScrollToSection(item.zone, item.colony)"
        class="btn btn-primary btn-floating-left rounded-circle btn-share" data-toggle="tooltip" data-placement="top" title="Copiar URL">
        <i class="fas fa-share-alt"></i>
      </a>
      <div class="property-thumbnail">
        <div class="offer-type-wrap">
          <span class="offer-type bg-danger">{{ item.bestOperation }}</span>
        </div>

        <div v-if="info.logo" class="watermark" :style="'background-image:url(' + info.logo + '); '"> </div>

        <img :src="item.smallphoto" id="propImg" :alt="item.alt" :title="item.namePropertyEs + '-IMAGE'"
          class="img-fluid" />
      </div>

      <div class="p-4 property-body">
        <strong class="property-price mb-0 d-block text-success">
          {{ item.namePropertyEs }}
        </strong>

        <h2 v-if="item.banner1" class="property-title">
          <a :href="item.banner1" target="_blank" rel="noopener noreferrer">
            <!--  etiqueta a :@click="goToProperty(item.folio, item.namePropertyEs)" -->
            Folleto
          </a>
        </h2>

        <h2 class="property-title">
          <a @click="openModal" data-toggle="modal" :data-target="'#exampleModal'">
            Galería
          </a>
        </h2>


        <h2 v-if="item.video" class="property-title">
          <a :href="item.video" target="_blank" rel="noopener noreferrer">
            Video
          </a>
        </h2>
        <!-- <h2 class="property-title">
          <a>
              Ubicación
          </a>
        </h2> -->

      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import { mapGetters } from "vuex";
import services from "@/store/_services";
import DetailCarousel from "../carousel/DetailCarousel.vue";
import GalleryCarousel from "../carousel/GalleryCarousel.vue";
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  components: {
    DetailCarousel,
    GalleryCarousel,
    VueAgile
  },

  props: [
    'name',
    'index',
    'item'],

  data() {
    return {
      folio: null,
      agentId: 0,
      url: "",
      amenities: [],
      showModal: false,
      isModalOpen: false,      
      showAlert: false
    };
  },
  computed: {
    ...mapGetters({
      info: '_getInfoCompany',
      agent: '_getDataAgent',
      // prop: "_getProperty",
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  created() {
    this.scrollToSection();

    this.folio = this.item.folio
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    // this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;

  },
  methods: {
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    copyURLAndScrollToSection(zone, colony) {
      // Función para formatear una cadena eliminando espacios y convirtiendo a minúsculas
      function formatString(str) {
        return this.removeAccents(str.toLowerCase()).replace(/\s+/g, '-');
      }

      // Formatear las zonas y colonias
      let formattedZone = formatString.call(this, zone);
      let formattedColony = formatString.call(this, colony);

      // Obtener la URL base del sitio web
      let baseURL = window.location.origin + window.location.pathname;

      // Generar la URL con el fragmento de anclaje
      let url = `${baseURL}#${formattedZone}-${formattedColony}`;

      // Copiar la URL al portapapeles
      navigator.clipboard.writeText(url)
        .then(() => {
          console.log('URL copiada con éxito');
        })
        .catch(err => {
          console.error('Error al copiar la URL: ', err);
        });
        
        this.showAlert = true; 
        setTimeout(() => {
          this.showAlert = false;
        }, 2500);
    },
    scrollToSection() {

      let hash = window.location.hash;

      // dirige a corretaje zona hotelera
      if (hash === "#corretaje-zona-hotelera") {
        setTimeout(() => {
          let section = document.getElementById("corretaje-zona-hotelera");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a corretaje  puerto cancun
      else if (hash === "#corretaje-puerto-cancun") {
        setTimeout(() => {
          let section = document.getElementById("corretaje-puerto-cancun");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a comercial industrial zona
      else if (hash === "#comercial-industrial-zona-hotelera") {
        setTimeout(() => {
          let section = document.getElementById("comercial-zona-hotelera");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      // dirige a 
      else if (hash === "#comercial-industrial-puerto-cancun") {
        setTimeout(() => {
          let section = document.getElementById("comercial-puerto-cancun");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#comercial-industrial-cancun-centro") {
        setTimeout(() => {
          let section = document.getElementById("comercial-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      //area para url eatica
      else if (hash === "#corretaje-aqua-residencial") {
        setTimeout(() => {
          let section = document.getElementById("corretaje-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#corretaje-supermanzana-518") {
        setTimeout(() => {
          let section = document.getElementById("corretaje-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }
      else if (hash === "#corretaje-lagos-del-sol") {
        setTimeout(() => {
          let section = document.getElementById("corretaje-cancun-centro");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 500);
      }

      //fin eccion epecial

      // si no hay nada después de la ulr, se mantiene en el incio
      else if (hash === "") {
        return;
      }
      // dirije a corretaje cancun centro
      // else if (hash === "#corretaje-cancun-centro") {
      //   setTimeout(() => {
      //     let section = document.getElementById("corretaje-cancun-centro");
      //     if (section) {
      //       section.scrollIntoView({ behavior: "smooth", block: "start" });
      //     }
      //   }, 500);
      // }
    },




    openModal() {
      this.isModalOpen = true;
      this.$emit('folio-selected', this.item.folio);
    },
    closeModal() {
      this.isModalOpen = false;
    },
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      let namePage = "Property";
      let params;

      if (this.nameRoute == 'Agent') {
        namePage = "PropertyAgent";
        params = {
          folio,
          agentId: this.agent.folio
        }
      } else {
        params = {
          folio,
          namePropFilt
        }
      }

      this.$router.push({
        name: namePage,
        params: params
      });
    },
  },
};
</script>

<style scoped>
.not-price {
  color: red !important;
  text-decoration: line-through;
}

img {
  width: 100%;
}

#propImg {
  height: 250px;
  width: 350px;
  cursor: pointer;
  object-fit: cover;
}

@media (max-width: 767px) {
  #contain_tarjet_property {
    width: 65%;
  }

  #propImg {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 520px) {
  #contain_tarjet_property {
    width: 100%;
  }
}

#leyendaPrecios {
  color: #000;
  font-size: 9px;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bg-danger {
  background-color: #C51333bf !important;
}

.watermark {
  height: 250px;
  width: 100%;
  background-size: 30%;
  z-index: 1;
}

.watermark {
  opacity: .3;
}
</style>