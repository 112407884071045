<template>
  <div class="form-search col-md-12">
    <div class="row justify-content-center">
      <!-- mobile list  -->
      <div  class="col-md-3 d-block d-md-none">
        <div class="select-wrap">
          <span class="icon  icon-angle-down"></span>
          <select
            class="form-control d-block ">
              <option class="text-black" selected value="">
                  PUERTO CANCÚN
              </option>
              <option class="text-black" value="1">
                ZONA HOTELERA
              </option>
              <option class="text-black" value="2">
                DIV. LISTADOS
              </option>
              <option class="text-black" value="3">
                CENTRO
              </option>
          </select>
        </div>
      </div>
      <!-- PUERTO CANCUN -->
      <div class="col-md-3 mb-2 d-none d-md-block">
        <label>Puerto Cancún</label>
        
      </div>
      <!-- ZONA HOTELERA -->
      <div class="col-md-3 mb-2 d-none d-md-block">
        <label>Zona hotelera</label>

      </div>
      <!-- DIV LISTADOS-->
      <div class="col-md-3 mb-2 d-none d-md-block">
        <label>Div. Listados</label>
        
      </div>
      <!-- CENTRO-->
      <div class="col-md-3 mb-2 d-none d-md-block">
        <label>Centro</label>

      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  
  computed: {
    ...mapGetters({
      input: "_getInputs",
      agent:'_getDataAgent',
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  mounted() {
      // this.$refs.address.focus();
  }
};
</script>


<style>

</style>
