<template>
<Preloader v-if="!dev"/>
<div v-else>
  <!-- <Banner :image="dev.images[0].largefile" :pageTitle="(activeLocale=='es'|| !dev.developmentNameEn)?dev.developmentNameEs:dev.developmentNameEn"/> -->

    <!-- Carousel -->
    <DetailCarousel :items="dev.images"/>
</div>
 
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import Mapper from "@/components/developments/Mapper.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    GoogleMap,
    DetailCarousel,
    Mapper
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return '$'+formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
  },
  methods:{
    goTo(ref){
      document.getElementById(ref).scrollIntoView({block: "nearest", behavior: "smooth"});
    },
    goToSection(){
      let folio=this.folio;
      this.$router.push({name:'DevelopmentSections',params: {folio}})
    }
  }
};
</script>


<style scoped>
.contactWA {
  color: #000;
}
.contactWA:hover {
  color: #C51333;
}
.pl-0,
.pr-3 {
  font-size: 20px;
}
h3 {
  font-size: 14px;
    letter-spacing: .1em;
}
.price {
  background-color: #9cd7d3c5;
  border-radius: 5px;
  width: 50%;
  height: auto;
  text-align: center;
}

.price h3 {
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.banners {
  float: none;
}
.texto1 {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 18px;
}
.texto2 {
  font-size: 15px;
  line-height: 27px;
  color: #C51333;
  font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}
.texto3 {
  display: none;
}
.whatsappButtom {
  color: #000;
}

.fbButton {
  float: right;
}

.buttons-content {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.button-flyer {
  display: block;
  color: #373737;
  width: auto;
  border: 2px solid #C51333;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;

  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.button-flyer:hover {
  background: #C51333 !important;
  color: rgb(255, 255, 255);
  outline: none !important;
}
.agent-photo {
  width: 150px;
  text-overflow: ellipsis;
  float: none;
  display: block;
  overflow: auto;
  flex-wrap: wrap;
  white-space: normal;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: auto;
}
.imagen1 {
  width: 200px !important;
  height: 100px !important;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;

  border-radius: 1px;
  cursor: zoom-in;
}
.imagen2 {
  height: 500px;
  width: 750px !important;
  cursor: pointer;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

#leyendaPrecios {
  float: right;
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 10px;
}

@media screen and (max-width: 415px) {
  .agentPhoto .email {
    display: none !important;
  }

  .show-more {
    max-height: 2000px !important;
    height: auto !important;
    overflow: initial !important;
    position: relative;
    transition: max-height 1s;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  display: inline-block;
  border: 1px solid #C51333;
  box-sizing: border-box;
}
.form-group textarea {
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #C51333;
  box-sizing: border-box;
}
.amenidades {
  list-style: none;
  margin: 0 !important;
  width: 100%;
  display: block;
  list-style: none;
  padding: 0.5rem;
  color: #373737;
  line-height: 27px;
  font-size: 15px;
}
.amenidades i {
  color: #C51333;
}
.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}
pre {
  white-space: pre-wrap;
  height: auto;
  overflow: hidden;
  background: transparent;
  border-color: transparent;
  color: #000;
  font-family: "DM Sans",Open Sans, sans-serif;
  font-size: 15px;
  text-align: justify;
}
.icon-phone {
  color: #C51333;
}

.contact-links {
  color: #373737;
}

.contact-links:hover {
  color: #C51333;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #C51333 !important;
  border-color: #C51333 !important;
}
.btn-outline-primary {
  color: #000;
  background-color: #f5f5f5;
  background-image: none;
  border-bottom: solid 3px #C51333 !important;
}

.references{
  margin: 10px 0;
}
.references span{
  margin-left: 10px;
}
.color-reference{
  width: 3rem;
  height: 1.6rem;
  border-radius: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}
.status-1{
  background: #93E945;
  border: 1px solid #86d241;
}
.status-2{
  background: #FFC848;
  border: 1px solid #f5c047;
}
.status-3{
  background: #E94545;
  border: 1px solid #CC3D3E;
}

.overall-scalable {width: 350px; height: 150px; overflow: hidden; -webkit-transition: all 1s;}
.scalable {color: #666; width: 350px; height: 150px; -webkit-transform-origin: top left; -webkit-transition: all 1s;}

.image-master-plan img{
  width: 100%;
  cursor: zoom-in;
}


.go-masterplan-button{
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  cursor: pointer;
  background-color: #fff !important;
  white-space: pre-wrap;
}
.go-masterplan-button:hover{
  background-color: #C51333 !important;
}
</style>
