<!-- GalleryCarousel.vue -->
<template>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <div v-if="prop.images" >
                    <DetailCarousel  :items="prop.images" />
                </div>

                <div v-else-if="dev.images" >
                    <DetailCarousel  :items="dev.images" />
                </div> 
                    
                <div v-else>
                    <SectionLoader />
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
import VueAgile from 'vue-agile';
import DetailCarousel from './DetailCarousel.vue';
import Preloader from "@/components/layout/Preloader.vue";
import { mapGetters } from "vuex";
import SectionLoader from "@/components/layout/SectionLoader.vue";

export default {
    components: {
        VueAgile,
        Preloader,
        DetailCarousel,
        SectionLoader
    },
    props: {
        folio: {
            type: String,
            default: ''
        }

    },
    data() {
        return {
            id: null,
            agentId: 0,
            url: "",
            amenities: [],
            localFolio: '',
            propiedades: [],
            desarrollos: [],
        };
    },
    computed: {
        ...mapGetters({
            prop: "_getProperty",
            error: "_getError",
            dev: "_getDevelopment",
        }),
        activeLocale() {
            return this.$i18n.locale;
        },
    },
    created() {
        let data = {
            folio: this.folio,
            agent: 0
        };
        this.$store.dispatch("getProperty", data);
        this.url = this.$store.state.URL_SITE + this.$route.path;
        this.propiedades = this.prop.images;

        this.$store.dispatch("getDevelopment", data);
        this.url = this.$store.state.URL_SITE + this.$route.path;
        this.desarrollos = this.dev.images;

    },

};

</script>

<style>

.modal {
    display: flex;
    justify-content: center; 
    align-items: center; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  
}

.modal-content {
    padding: 20px;
    border: 1px solid #888;
    max-width: 800px;
    width: 80%;  
    max-height: 100%;
    overflow: auto; 
    position: relative;  
}


.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Carousel item styles */
.carousel-item {
    text-align: center;
}

.carousel-item img {
    max-width: 100%;
    height: auto;
}
</style>